import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'

type Props = {
  title?: string
  description?: string
  image?: string
}

const AppHead: FC<Props> = ({ title, description, image }) => {
  const router = useRouter()

  const pageTitle = title ? `${title} | LearnWise Chat` : 'LearnWise Chat'

  return (
    <Head>
      <title>{pageTitle}</title>

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content="LearnWise" />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_URL}${router.asPath}`} />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  )
}

export default AppHead
